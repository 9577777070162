import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Inner from '../../components/templates/Inner';
import { Divider, Box, Flex, Text } from '../../components/atoms';
import FootNote from '../../components/molecules/FootNote';
import img01 from '../../assets/img/design-tips/icons/01.png';
import img02 from '../../assets/img/design-tips/icons/02.png';
import img03 from '../../assets/img/design-tips/icons/03.png';
import img04 from '../../assets/img/design-tips/icons/04.png';
import img05 from '../../assets/img/design-tips/icons/05.png';
import img06 from '../../assets/img/design-tips/icons/06.png';
import img07 from '../../assets/img/design-tips/icons/07.png';
import img08 from '../../assets/img/design-tips/icons/08.png';
import img09 from '../../assets/img/design-tips/icons/09.png';
import img10 from '../../assets/img/design-tips/icons/10.png';
import img11 from '../../assets/img/design-tips/icons/11.png';
import img12 from '../../assets/img/design-tips/icons/12.png';
import img13 from '../../assets/img/design-tips/icons/13.png';
import img14 from '../../assets/img/design-tips/icons/14.png';
import { designTips as nav } from '../../nav';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Inner nav={nav} title="Icons" mdxType="Inner">
      <h3>{`Icons`}</h3>
      <p>{`  Basic application icons used in forms, components and copy`}</p>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Don’t mix styles`}</h4>
      <p>{`  There are two popular icon styles: outline and filled`}</p>
  <Flex my="xxl" mdxType="Flex">
    <Box width={360} mr={152} mdxType="Box">
      <Box mdxType="Box"><img src={img02} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">All icons got one style.</Box>
    </Box>
    <Box width={360} mdxType="Box">
      <Box mdxType="Box"><img src={img01} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">3 icons in outline style and 1 filled mixed </Box>
    </Box>
  </Flex>
      <blockquote>
        <p parentName="blockquote">{`Exception: Use filled style to show an active state.`}</p>
      </blockquote>
  <Flex my="xxl" mdxType="Flex">
    <Box width={320} mr={152} mdxType="Box">
      <Box mdxType="Box"><img src={img04} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Follow the consistency of forms. All icons in one style.</Box>
    </Box>
    <Box width={320} mdxType="Box">
      <Box mdxType="Box"><img src={img03} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">The calendar icon has sharp edges while other icons have smooth edges.</Box>
    </Box>
  </Flex>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Think twice paint once`}</h4>
  <Text maxWidth={560} mdxType="Text">
    Generally, icons change colors after shifting their state: hover, active, focus, error, success. Omit use of colors without the intention of indicating their state.
  </Text>
  <Flex my="xxl" mdxType="Flex">
    <Box width={360} mr={152} mdxType="Box">
      <Box mdxType="Box"><img src={img06} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">2 colors used with one highlighting active tab. </Box>
    </Box>
    <Box width={360} mdxType="Box">
      <Box mdxType="Box"><img src={img05} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">3 different colors used without any purpose. </Box>
    </Box>
  </Flex>
      <blockquote>
        <p parentName="blockquote">{`Exception: Some apps are well suited for colored icons. This approach creates a playful atmosphere. It also may work as color coding.`}</p>
      </blockquote>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Use popular sizing`}</h4>
      <p>{`  Basic icon sizes are 18x18px and 24x24px.`}</p>
  <Flex my="xxl" mdxType="Flex">
    <Box width={320} mr={152} mdxType="Box">
      <Box mdxType="Box"><img src={img08} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Consistent 24x24px icon size.</Box>
    </Box>
    <Box width={320} mdxType="Box">
      <Box mdxType="Box"><img src={img07} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Different icon size in similar components.</Box>
    </Box>
  </Flex>
  <Flex my="xxl" mdxType="Flex">
    <Box width={320} mr={152} mdxType="Box">
      <Box mdxType="Box"><img src={img10} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Use small icons to complement the text.</Box>
    </Box>
    <Box width={320} mdxType="Box">
      <Box mdxType="Box"><img src={img09} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Don’t use big icons…</Box>
    </Box>
  </Flex>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Add details with purpose`}</h4>
  <Text maxWidth={560} mdxType="Text">
    Avoid high-detailed icons as they distract from main thing. Simplify them for better clarity.
  </Text>
  <Flex my="xxl" mdxType="Flex">
    <Box width={320} mr={152} mdxType="Box">
      <Box mdxType="Box"><img src={img12} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Good and simple icon, easy to recognize</Box>
    </Box>
    <Box width={320} mdxType="Box">
      <Box mdxType="Box"><img src={img11} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Too many needless detail</Box>
    </Box>
  </Flex>
  <Flex mt="xxl" mdxType="Flex">
    <Box width={320} mr={152} mdxType="Box">
      <Box mdxType="Box"><img src={img14} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Simple icon with familiar angle, easy to recognize</Box>
    </Box>
    <Box width={320} mdxType="Box">
      <Box mdxType="Box"><img src={img13} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Don’t tilt, rotate, scale</Box>
    </Box>
  </Flex>
    </Inner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      